window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    const cors = window.cors = require('cors');

    // BOOTSTRAP
    require('bootstrap');

    // SWEETALERT
    const Swal = window.Swal = require('sweetalert2');

    // MOMENT
    require('moment/dist/locale/tr');
    window.moment = require('moment');

    // INPUTMASK
    window.Inputmask = require('inputmask/dist/inputmask');
    require('inputmask/dist/jquery.inputmask');

    // SELECT2
    window.Select2 = require('admin-lte/plugins/select2/js/select2.full.min.js');
    require('admin-lte/plugins/select2/js/i18n/tr.js');

    // SLUGIFY
    const Slugify = window.Slugify = require('slugify');

    // ADMIN LTE
    require('admin-lte/dist/js/adminlte');

    // JQUERY UI
    // require('jquery-ui-dist/jquery-ui.min');

    // ICHECK
    require('admin-lte/plugins/icheck-bootstrap');

    // FONTAWESOME
    require('admin-lte/plugins/fontawesome-free/css/all.css');

    // DATATABLES
    require('admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4');
    // require('admin-lte/plugins/datatables-autofill/js/autoFill.bootstrap4');
    require('admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4');
    require('admin-lte/plugins/datatables-buttons/js/buttons.colVis');
    require('admin-lte/plugins/datatables-buttons/js/buttons.flash');
    require('admin-lte/plugins/datatables-buttons/js/buttons.html5');
    require('admin-lte/plugins/datatables-buttons/js/buttons.print');
    require('admin-lte/plugins/datatables-colreorder/js/colReorder.bootstrap4');
    // require('admin-lte/plugins/datatables-fixedcolumns/js/fixedColumns.bootstrap4');
    // require('admin-lte/plugins/datatables-fixedheader/js/fixedHeader.bootstrap4');
    require('admin-lte/plugins/datatables-keytable/js/keyTable.bootstrap4');
    require('admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4');
    // require('admin-lte/plugins/datatables-rowgroup/js/rowGroup.bootstrap4');
    // require('admin-lte/plugins/datatables-rowreorder/js/rowReorder.bootstrap4');
    // require('admin-lte/plugins/datatables-scroller/js/scroller.bootstrap4');
    // require('admin-lte/plugins/datatables-searchbuilder/js/searchBuilder.bootstrap4');
    // require('admin-lte/plugins/datatables-searchpanes/js/searchPanes.bootstrap4');
    // require('admin-lte/plugins/datatables-select/js/select.bootstrap4');
    window.pdfMake = require('pdfmake');
    window.pdfFonts = require('pdfmake/build/vfs_fonts');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    require('admin-lte/plugins/jszip/jszip');

    // FULLCALENDAR
    require('admin-lte/plugins/fullcalendar/main');
    require('admin-lte/plugins/fullcalendar/locales/tr');

    // BOOTSTRAP COLORPICKER
    // require('admin-lte/plugins/bootstrap-colorpicker/js/bootstrap-colorpicker');
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common["Authorization"] = `Bearer ${$('meta[name="api-token"]').attr('content')}`;
window.axios.defaults.headers.common["X-CSRF-TOKEN"] = `${$('meta[name="csrf-token"]').attr('content')}`;
window.axios.defaults.headers.common["Accept"] = 'application-json';
window.axios.defaults.headers.common["Content-Type"] = 'application-json';
$.ajaxSetup({
    headers: {
        'Accept': 'application-json',
        'Content-Type': 'application-json',
        'Authorization': `Bearer ${$('meta[name="api-token"]').attr('content')}`,
        'X-CSRF-TOKEN': `${$('meta[name="csrf-token"]').attr('content')}`,
    }
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

$(function () {
    // INPUTMASKS
    $('.phone').inputmask({ mask: "0(###) ### ## ##" });
    $('.tc').inputmask({ mask: "###########" });
    $('.iban').inputmask({ mask: "TR## #### #### #### #### #### ##" });
    $('.money').inputmask('decimal', {
        'alias': 'numeric',
        'groupSeparator': '.',
        'autoGroup': true,
        'digits': 2,
        'radixPoint': ",",
        'digitsOptional': true,
        'allowMinus': false,
        'prefix': `₺`,
        'placeholder': '',
        'rightAlign': false,
        'unmaskAsNumber': false
    });

    // INPUTS
    $('.custom-file-input').on('change', function () {
        let fileName = $(this).val().split('\\');
        if (Array.isArray(fileName)) {
            $(this).next('.custom-file-label').html(fileName[fileName.length - 1]);
        } else {
            $(this).next('.custom-file-label').html(fileName);
        }
    });
});

$.extend(true, $.fn.dataTable.defaults, {
    "dom": '<"row"<"col-md-6"l><"col-md-6"f>><"row"<"col-md-12"tr>><"row"<"col-md-6"i><"col-md-6"p>><"row"<"col-md-12"B>>',
    "serverSide": true,
    "processing": true,
    "stateSave": false,
    "responsive": true,
    "colReorder": true,
    "autoFill": true,
    "keys": true,
    "destroy": true,
    "retrieve": false,
    "iDisplayLength": 10,
    "paging": true,
    "lengthChange": true,
    "searching": true,
    "ordering": true,
    "info": true,
    "autoWidth": false,
    "scrollX": false,
    "processing": "<i class='fas fa-refresh fa-spin'></i>",
    "language": smed.locale == 'tr' ? require('./datatables_tr.json') : '',
    "buttons": [
        {
            "extend": 'pdf',
            "text": 'PDF',
            "pageSize": 'A4',
            "orientation": 'landscape',
            "exportOptions": {
                "columns": [1, 2, 3]
            },
        }, {
            "extend": 'print',
            "text": 'YAZDIR',
            "exportOptions": {
                "columns": [1, 2, 3]
            },
        },
    ],
});

$.fn.select2.defaults.set("theme", "bootstrap4");
$.fn.select2.defaults.set("language", smed.locale);
$.fn.select2.defaults.set("placeholder", "SEÇİM YAPINIZ");
$.fn.select2.defaults.set("allowClear", true);
$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("tags", false);
$.fn.select2.defaults.set("containerCssClass", "bg-light");
$.fn.select2.defaults.set("dropdownCssClass", "bg-light");
$.fn.modal.Constructor.prototype.enforceFocus = function () { };

// GLOBAL REACT COMPONENTS
require('./components/SearchTrainees');
// require('./components/AddCompany');

// EDUCATIONS
// require('./Educations/EducationCalendar');

// OFFERS
require('./Offers/OfferServices');

// TRAINESS
require('./Trainees/AddPassportPhoto');
// require('./Trainees/EducationTimeIncoming');

// ExchangeRate
require('./components/ExchangeRate');

require('./diacritics-neutralise');