import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const defaultSrc = "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

export const ImageCropper = (props) => {
    const [image, setImage] = useState(defaultSrc);
    const [cropData, setCropData] = useState(null);
    const [cropper, setCropper] = useState();
    const [loadingImage, setLoadingImage] = useState(false);
    const onChange = (e) => {
        e.preventDefault();
        setLoadingImage(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            props.setImage(cropper.getCroppedCanvas().toDataURL());
        }
    };

    return (
        <div>
            <input type="file" onChange={onChange} className="mb-3"/>
            {loadingImage && <Cropper
                style={{ height: "300px" }}
                zoomTo={0.1}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                    setCropper(instance);
                }}
                guides={true}
            />}
            {loadingImage && <div className="row">
                <div className="col-12 mt-3"><button className="btn btn-outline-dark" onClick={getCropData}>RESMİ KES</button></div>
                <div className="col-12 mt-3">{cropData && <img style={{ height: "100px" }} src={cropData} alt="cropped" />}</div>
            </div>}
        </div>
    );
};