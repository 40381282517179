import React from 'react';
import ReactDOM from 'react-dom';

class OfferServices extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            services: [],
        };
    }
    render() {
        return (
            <>
                <div>TEST</div>
            </>
        );
    }
}

if (document.getElementById('offer-services')) {
    const items = document.getElementById('offer-services');
    const props = Object.assign({}, items.dataset)
    ReactDOM.render(<OfferServices {...props} />, items);
}

export default OfferServices;

