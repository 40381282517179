import axios from 'axios';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';

class ExchangeRate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            update_date: '',
            currencies: [],
        };
    }

    componentDidMount() {
        this.getExchangeRates();
    }

    getExchangeRates = async () => {
        try {
            this.setState({ loading: true });
            let response = await fetch('https://finans.truncgil.com/today.json').then(res => res.json());
            this.setState({
                update_date: response.Update_Date,
                currencies: Object.entries({
                    'DOLAR': response['USD'],
                    'EURO': response['EUR'],
                    'STERLİN': response['GBP'],
                    'ONS': response['ons'],
                    'GRAM ALTIN': response['gram-altin'],
                    'ÇEYREK ALTIN': response['ceyrek-altin'],
                    'YARIM ALTIN': response['yarim-altin'],
                    'TAM ALTIN': response['tam-altin'],
                })
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({loading: false});
        }
    }

    handleSync = () => {
        this.getExchangeRates();
    }

    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card card-outline card-dark shadow-sm">
                        <div className="card-header">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="lead font-weight-bold">
                                    <i className="fas fa-lira-sign"></i>
                                    <span className="ml-1 d-none d-lg-inline d-xl-inline">DÖVİZ KURLARI</span>
                                </div>
                                <div className="card-tools">
                                    <button type="button" className="btn btn-outline-dark mr-1" onClick={() => this.handleSync()}>
                                        {this.state.loading ? <i className="fas fa-sync fa-spin"></i> : <i className="fas fa-sync"></i>}
                                    </button>
                                    <button type="button" className="btn btn-outline-dark" data-card-widget="maximize"><i className="fas fa-expand fa-fw"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                        {
                            this.state.loading
                                ? <div className="w-100 h-100 d-flex justify-content-center align-items-center text-center p-4"><i className="fas fa-sync fa-spin fa-3x"></i></div>
                                : <div>
                                    <div className="badge badge-warning p-2 justify-content-center align-items-center mb-3" role="alert">{moment(this.state.update_date).format('DD.MM.YYYY hh:mm:ss')}</div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered m-0">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="align-middle">DÖVİZ</th>
                                                    <th className="align-middle">ALIŞ</th>
                                                    <th className="align-middle">SATIŞ</th>
                                                    <th className="align-middle">DEĞİŞİM</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.currencies.map((item, index) => (
                                                    <tr key={index}>
                                                        <th className="align-middle">{item[0]}</th>
                                                        <td className="align-middle">{item[1]['Alış']}</td>
                                                        <td className="align-middle">{item[1]['Satış']}</td>
                                                        <td className="align-middle">{item[1]['Değişim']}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        }
                            <div className="alert alert-info text-center font-weight-bold mt-3 mb-0" role="alert">VERİLER T.C.M.B ÜZERİNDEN ÇEKİLMEKTEDİR!</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExchangeRate;

if (document.getElementById('exchange-rate')) {
    ReactDOM.render(<ExchangeRate />, document.getElementById('exchange-rate'));
}
