import axios from 'axios';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { ImageCropper } from '../components/ImageCropper';
class AddPassportPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tc: '88848838542',
            trainee: {},
            addImage: false,
            image: null,
        };
    }

    onChange = (e) => {
        const { value } = e.target;
        this.setState({
            tc: value,
        });
    }

    searchTrainee = async (e) => {
        try {
            if(this.state.tc.length && this.state.tc.length === 11){
                this.setLoading(true);
                const res = await axios.post("/api/trainees/search-trainee-with-tc", { "tc": this.state.tc });
                this.setState({
                    trainee: res.data,
                });
            }else{
                Swal.fire({
                    title: 'ARAMA ALANI BOŞ VEYA EKSİK',
                    text: 'ARAMA ALANI BOŞ VEYA EKSİK BIRAKILAMAZ!',
                    icon: 'warning',
                    confirmButtonText: 'TAMAM',
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    setLoading(value) {
        this.setState({
            loading: value,
        });
    }

    setImage = (image) => {
        this.setState({image});
    }

    handleClickAddImage = (e) => {
        this.setState({
            addImage: true,
        });
    }

    handleChangeImage = async (e) => {
        try {
            const response = await axios.put(`/api/trainees/change-passport-photo`, {
                tc: this.state.trainee.tc,
                image: this.state.image,
            });
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control"
                        placeholder="T.C. KİMLİK NUMARASI GİRİNİZ"
                        maxLength="11"
                        minLength="11"
                        value={this.state.tc}
                        onChange={(e) => this.onChange(e)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                this.searchTrainee();
                            }
                        }}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={(e) => this.searchTrainee(e)}>
                            {this.state.loading ? <i className="fas fa-sync fa-spin"></i> : <i className="fas fa-search"></i>}
                        </button>
                    </div>
                </div>
                {this.state.trainee.hasOwnProperty('tc') && <div className="table-responsive">
                    <table className="table table-bordered">
                        <caption>
                            <button className="btn btn-outline-dark btn-lg" onClick={this.handleClickAddImage}>
                                <i className="fas fa-camera"></i>
                                <span className="ml-2">VESİKALIK EKLE</span>
                            </button>
                        </caption>
                        <thead className="thead-light">
                            <tr>
                                <th className="aling-middle">T.C.</th>
                                <th className="aling-middle">ADI SOYADI</th>
                                <th className="aling-middle">FİRMA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="align-middle">{this.state.trainee.hasOwnProperty('tc') && this.state.trainee.tc}</td>
                                <td className="align-middle">{this.state.trainee.hasOwnProperty('name') && this.state.trainee.name}</td>
                                <td className="align-middle">{this.state.trainee.hasOwnProperty('company') && this.state.trainee.company.name}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                {this.state.addImage && <ImageCropper setImage={this.setImage} />}
                <button className="btn btn-warning mt-3" onClick={() => this.handleChangeImage()}>GÜNCELLE</button>
            </div>
        );
    }
}

if (document.getElementById('add-passport-photo')) {
    const items = document.getElementById('add-passport-photo');
    const props = Object.assign({}, items.dataset);
    ReactDOM.render(<AddPassportPhoto {...props} />, items);
}

export default AddPassportPhoto;