import axios from 'axios';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';

class SearchTrainees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trainees: [],
            search: '',
            loading: false,
            selectedCount: 0,
        }
        this.changeSearch = this.changeSearch.bind(this);
        this.searchTrainee = this.searchTrainee.bind(this);
        this.transferTrainee = this.transferTrainee.bind(this);
        this.deleteTrainee = this.deleteTrainee.bind(this);
    }

    componentDidMount(){
        if(JSON.parse(this.props.trainees).length){
            let propsTrainees = JSON.parse(this.props.trainees).map(item => {
                item.transfer = true;
                return item;
            });
            this.setState({
                trainees: [...new Set([...this.state.trainees, ...propsTrainees])],
                selectedCount: propsTrainees.length ?? 0,
            });
        }
    }

    changeSearch(e) {
        this.setState({
            search: e.target.value,
        });
    }

    transferTrainee(trainee) {
        trainee.transfer = true;
        this.setState({
            trainees: [...new Set([...this.state.trainees, trainee])],
            selectedCount: this.state.trainees.filter(item => item.transfer === true).length,
        });
    }

    deleteTrainee(trainee) {
        trainee.transfer = false;
        this.setState({
            trainees: [...new Set([...this.state.trainees, trainee])],
            selectedCount: this.state.trainees.filter(item => item.transfer === true).length,
        });
    }

    async searchTrainee() {
        try {
            if(this.state.search.length){
                this.setLoading(true);
                let res = await axios.post("/api/trainees/search", { "search": this.state.search });
                this.state.trainees.length && this.state.trainees.map(trainee => {
                    res.data.length && res.data.map((item,index) => {
                        item.transfer = false;
                        if(item.tc === trainee.tc) res.data.splice(index, 1);
                        return item;
                    });
                });
                this.setState({
                    trainees: [...new Set([...this.state.trainees, ...res.data])],
                });
            }else{
                Swal.fire({
                    title: 'ARAMA ALANI BOŞ',
                    text: 'ARAMA ALANI BOŞ BIRAKILAMAZ!',
                    icon: 'warning',
                    confirmButtonText: 'TAMAM',
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    setLoading(value) {
        this.setState({
            loading: value,
        });
    }

    changeEducation = async () => {
        try {
            this.setLoading(true);
            let educationIds = [];
            let educations = $('#educations').select2('data');
            educations.length && educations.map(education => {
                educationIds.push(education.id);
            });
            let res = await axios.post("/api/trainees/search", { educations: educationIds });
            this.state.trainees.length ? this.state.trainees.map(trainee => {
                res.data.length && res.data.map((item,index) => {
                    item.transfer = true;
                    if(item.tc === trainee.tc) res.data.splice(index, 1);
                    return item;
                });
            }) : res.data.length && res.data.map((item,index) => {
                item.transfer = true;
                return item;
            });
            this.setState({
                trainees: [...new Set([...this.state.trainees, ...res.data])],
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
            this.setState({
                selectedCount: this.state.trainees.filter(item => item.transfer === true).length,
            });
        }
    }

    render() {
        $('#educations').on('select2:select', () => this.changeEducation());

        return (
            <div className="col-md-12">
                <div className="modal fade" id="SearchTrainees" tabIndex="-1" aria-labelledby="SearchTraineesLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-lg modal-xl modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="SearchTraineesLabel">KURSİYER ARAMA</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"
                                        placeholder="TC / ADI SOYADI / FİRMA ADI"
                                        aria-label="TC / ADI SOYADI / FİRMA ADI"
                                        aria-describedby="search-trainee"
                                        value={this.state.search}
                                        onChange={(e) => this.changeSearch(e)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                this.searchTrainee();
                                            }
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" onClick={(e) => this.searchTrainee(e)}>
                                            {this.state.loading ? <i className="fas fa-sync fa-spin"></i> : <i className="fas fa-search"></i>}
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="align-middle">TC</th>
                                                <th className="align-middle">ADI SOYADI</th>
                                                <th className="align-middle">FİRMA</th>
                                                <th className="align-middle">İŞLEMLER</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.trainees.length > 0 ? this.state.trainees.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="align-middle">{item.tc}</td>
                                                        <td className="align-middle">{item.name}</td>
                                                        <td className="align-middle">{item.company.name}</td>
                                                        <td className="align-middle">
                                                            <button type="button"
                                                                className="btn btn-info btn-sm"
                                                                onClick={() => this.transferTrainee(item)}
                                                                disabled={item?.transfer || false}
                                                            >
                                                                <i className="fas fa-exchange-alt"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )) : <tr><td colSpan="99" className="text-center">KURSİYER BULUNAMADI</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    <i className="fas fa-times fa-fw"></i>
                                    <span className="d-none d-lg-inline d-xl-inline">KAPAT</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <caption>
                            <button type="button" className="btn btn-dark" data-toggle="modal" data-target="#SearchTrainees">
                                <i className="fas fa-plus fa-fw"></i>
                                <span className="d-none d-lg-inline d-xl-inline">KURSİYER ARA</span>
                            </button>
                        </caption>
                        <thead>
                            <tr>
                                <th className="align-middle"></th>
                                <th className="align-middle">TC</th>
                                <th className="align-middle">ADI SOYADI</th>
                                <th className="align-middle">FİRMA</th>
                                <th className="align-middle">İŞLEMLER</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (this.state.trainees.length > 0) 
                                    && this.state.trainees.filter(item => item.transfer === true).map((item, index) => (
                                        <tr key={index}>
                                            <td className="align-middle text-center">
                                                <div className="icheck-warning">
                                                    <input type="checkbox" name="trainees[]" id={"trainee-" + item.tc} value={item.tc} defaultChecked={"checked"} />
                                                    <label htmlFor={"trainee-" + item.tc}></label>
                                                </div>
                                            </td>
                                            <td className="align-middle">{item.tc}</td>
                                            <td className="align-middle">{item.name}</td>
                                            <td className="align-middle">{item.company.name}</td>
                                            <td className="align-middle">
                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => this.deleteTrainee(item)}>
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                            }
                            {
                                this.state.loading 
                                    ? <tr><td colSpan="99" className="align-middle text-center"><i className="fas fa-sync fa-spin fa-lg my-4"></i></td></tr>
                                    : this.state.selectedCount <= 0 && <tr><td colSpan="99" className="align-middle text-center">KURSİYER EKLİ DEĞİL / KURSİYER ARADAN KURSİYER AKTARINIZ</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default SearchTrainees;

if (document.getElementById('search-trainees')) {
    let items = document.getElementById('search-trainees');
    let props = Object.assign({}, items.dataset)
    ReactDOM.render(<SearchTrainees {...props} />, items);
}
